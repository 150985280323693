exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-external-redirect-tsx": () => import("./../../../src/pages/ExternalRedirect.tsx" /* webpackChunkName: "component---src-pages-external-redirect-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-qr-1-tsx": () => import("./../../../src/pages/qr1.tsx" /* webpackChunkName: "component---src-pages-qr-1-tsx" */),
  "component---src-pages-qr-2-tsx": () => import("./../../../src/pages/qr2.tsx" /* webpackChunkName: "component---src-pages-qr-2-tsx" */),
  "component---src-pages-qr-3-tsx": () => import("./../../../src/pages/qr3.tsx" /* webpackChunkName: "component---src-pages-qr-3-tsx" */),
  "component---src-pages-qr-4-tsx": () => import("./../../../src/pages/qr4.tsx" /* webpackChunkName: "component---src-pages-qr-4-tsx" */),
  "component---src-pages-resume-tsx": () => import("./../../../src/pages/resume.tsx" /* webpackChunkName: "component---src-pages-resume-tsx" */),
  "component---src-pages-uses-tsx": () => import("./../../../src/pages/uses.tsx" /* webpackChunkName: "component---src-pages-uses-tsx" */),
  "component---src-pages-writing-tsx": () => import("./../../../src/pages/writing.tsx" /* webpackChunkName: "component---src-pages-writing-tsx" */)
}

